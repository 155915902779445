
import { Options, Vue } from 'vue-class-component';
import { OrderClient } from '@/services/Services';
import ConfirmDeleteOrder from '@/components/confirmDeleteOrder.vue';
import * as OM from '@/model';
import { LocalizationServices } from '@/services/LocalizationServices';


@Options({
    components:{
        ConfirmDeleteOrder
    }
})
export default class OrderList extends Vue {

    orders: OM.OrderResumeVM[] = [];
    
    created(){
        this.init();
        
    }

    orderDetail(orderId: string) {
        this.$router.push('/order-detail/' + orderId);
    }

    deleteOrder(orderId: string){
        var text = LocalizationServices.getLocalizedValue("app_vuoieliminarequestoordine", "Sei sicuro di voler eliminare questo ordine?");
        this.$opModal.show(ConfirmDeleteOrder ,{
            orderIdentifier : orderId,
            text: text,
            okCb: () => {
                OrderClient.deleteOrderById(orderId)
                .then(x => {
                    this.$opModal.closeLast();
                    this.init();
                })
            }
        })
    }

    init(){
        OrderClient.getUserOrders("")
        .then(x => {
            this.orders = x;
            console.log(x)
        })
    }
}

